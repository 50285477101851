import IHeading from "@/components/IHeading"
import ILine from "@/components/ILine"
import IParagraph from "@/components/IParagraph"
import Layout from "@/components/Layout"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import Seo from "@/components/seo"
import * as React from "react"
import DesignIcon from "../../images/our-process/Design.svg"
import GrowthIcon from "../../images/our-process/Growth.svg"
import StrategyIcon from "../../images/our-process/Strategy.svg"
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined"
import GroupWorkOutlinedIcon from "@material-ui/icons/GroupWorkOutlined"
import AspectRatioOutlinedIcon from "@material-ui/icons/AspectRatioOutlined"
import ImportantDevicesOutlinedIcon from "@material-ui/icons/ImportantDevicesOutlined"
import TrendingUp from "@material-ui/icons/TrendingUp"
import Whatshot from "@material-ui/icons/Whatshot"
import Group from "@material-ui/icons/Group"
import Timeline from "@material-ui/icons/Timeline"
import FAQs from "@/components/FAQs"
import CallToAction from "@/components/CallToAction"
import { graphql, PageProps } from "gatsby"

interface BigcommercePageData {
  mdx: {
    body: string
    excerpt: string
    frontmatter: {
      title: string
      description: string
      iconName: string
      color?: string
    }
  }
}
const BigcommercePage : React.FC<PageProps<BigcommercePageData>> = ({ data }) => {
  const { frontmatter, excerpt } = data.mdx
  const { title, description } = frontmatter
  const feature = [
    {
      title: "Responsive, customizable themes",
    },
    {
      title: " Built-in SEO and marketing tools",
    },
    {
      title: "Multi-channel selling capabilities",
    },
    {
      title: "Robust API for custom integrations",
    },
    {
      title: "Advanced security features",
    },
    {
      title: "B2B functionality",
    },
    {
      title: "Headless commerce options",
    },
  ]
  const experties = [
    {
      title: "Years of experience",
      content:
        "5+ years of specialized BigCommerce development. 75+ successful BigCommerce projects delivered. ",
      imgSrc: DesignIcon,
    },
    {
      title: "Notable projects",
      content:
        " Developed a custom B2B portal for an industrial supplies company, resulting in a 40% increase in online B2B sales. Implemented a headless BigCommerce solution for a fast-growing D2C brand, leading to a 30% improvement in site performance and conversion rates",
      imgSrc: GrowthIcon,
    },
    {
      title: "Certifications and partnerships",
      content:
        "BigCommerce Elite Partner. Multiple team members with BigCommerce Certified Developer credentials. BigCommerce Preferred Technology Partner",
      imgSrc: StrategyIcon,
    },
  ]
  const items = [
    {
      imgSrc: <ImportantDevicesOutlinedIcon />,
      title: "Development",
      desc: " Custom theme development and design. App and extension development. Headless commerce implementations.  B2B functionality customization",
    },
    {
      imgSrc: <AspectRatioOutlinedIcon />,
      title: "Migration",
      desc: "Seamless migration from other platforms to BigCommerce. Data migration and integrity assurance. Minimal downtime during transition",
    },
    {
      imgSrc: <GroupOutlinedIcon />,
      title: " Support and maintenance",
      desc: "Ongoing BigCommerce store management. Regular platform updates and security patches. Performance monitoring and enhancement.",
    },
    {
      imgSrc: <GroupWorkOutlinedIcon />,
      title: " Integration",
      desc: " Third-party system integrations (ERP, CRM, PIM). Custom API development and implementation. Payment gateway and shipping provider integrations.",
    },
    {
      imgSrc: <TrendingUp />,
      title: "Optimization",
      desc: "Store performance optimization.  Conversion rate optimization (CRO). Search engine optimization (SEO).",
    },
  ]
  const helpItems = [
    {
      icon: <Whatshot />,
      title: "Unique selling points",
      content:
        "Deep understanding of BigCommerce's architecture and best practices. Experience with complex e-commerce implementations and integrations. Focus on driving measurable business results through technology",
    },
    {
      icon: <Group />,
      title: "Team expertise",
      content:
        " Certified BigCommerce developers and solution architects. E-commerce UX/UI specialists. Experienced project managers and business analysts.",
    },
    {
      icon: <Timeline />,
      title: " Process highlights",
      content:
        "Comprehensive discovery and strategy planning. Agile development methodology with regular client feedback. Rigorous quality assurance and testing. Smooth deployment and post-launch optimization",
    },
  ]
  const faqData = [
    {
      question: "How does BigCommerce compare to other e-commerce platforms?",
      answer:
        "BigCommerce stands out for its robust out-of-the-box features, scalability, and flexibility. It offers a balance between ease of use and customization capabilities, making it suitable for businesses at various stages of growth. Its built-in features often reduce the need for additional apps, potentially lowering total cost of ownership",
    },
    {
      question: " Can BigCommerce handle both B2C and B2B sales?",
      answer:
        "Yes, BigCommerce offers strong capabilities for both B2C and B2B e-commerce. Its B2B Edition includes features like customer groups, price lists, quote management, and account management tools, making it an excellent choice for businesses serving both markets.",
    },
    {
      question: "  What is headless commerce, and can BigCommerce support it?",
      answer:
        "Headless commerce separates the front-end presentation layer from the back-end e-commerce functionality. BigCommerce fully supports headless implementations through its robust API, allowing businesses to create unique, high-performance storefronts using technologies like React, Vue, or Next.js while leveraging BigCommerce's powerful back-end",
    },
    {
      question: "How does BigCommerce perform in terms of site speed and SEO?",
      answer:
        "BigCommerce is known for its excellent performance out of the box. It offers fast-loading themes and built-in SEO tools. However, to maximize speed and SEO benefits, we recommend custom optimizations tailored to your specific store and needs, which our team can provide.",
    },
    {
      question:
        "Can you help with post-launch marketing and growth strategies for my BigCommerce store?",
      answer:
        "Absolutely. Beyond development and optimization, we offer strategic guidance to help grow your online business. This includes assistance with SEO strategies, conversion rate optimization, multi-channel selling setup, and leveraging BigCommerce's built-in marketing tools effectively.",
    },
  ]
  return (
    <>
      <Layout>
        <Seo
          title={title}
          description="Accelerate your ecommerce growth with our BigCommerce expertise. Custom development and optimization for your online store."
        />
        <div data-aos="fade-up">
        <PageHeading title={title} description={description}></PageHeading>
        </div>
        <div data-aos="fade-up">
          <PageIntro>
            {excerpt}
          </PageIntro>
        </div>
        <section className="w-full my-16">
          <div className="container mx-auto">
            <div className="w-full">
              <div>
                <div data-aos="fade-up">
                  <IHeading align="left">Standout Offerings</IHeading>
                </div>
                <div className="xl:w-2/4 lg:w-3/4" data-aos="fade-up">
                  <IParagraph>
                    BigCommerce caters to businesses of all sizes, from small
                    startups to large enterprises. It's particularly well-suited
                    for rapidly growing businesses, B2B companies, and those
                    requiring complex customizations or integrations.
                  </IParagraph>
                </div>
              </div>
              <div className="">
                <ILine align="left" />
              </div>
              <div className="grid grid-cols-1 py-10 -mx-1 overflow-hidden md:grid-cols-2 gap-x-10">
                {feature.map((item: any, index: number) => {
                  return (
                    <div
                      className="relative w-full px-6 my-6 overflow-hidden group"
                      key={index}
                      data-aos="fade-up"
                    >
                      <IHeading size="three">{item.title}</IHeading>
                      <IParagraph>{item.content}</IParagraph>
                      <div
                        className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                        style={{ zIndex: "-1" }}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="w-full pt-12 pb-24 my-20 bg-gray-800 lg:my-36 md:pt-24 dark:bg-primary-900">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center" color="white">
                Our Expertise
              </IHeading>
            </div>
            <div data-aos="fade-up">
              <IParagraph align="center" color="white">
                Our team brings extensive experience in BigCommerce development
                and optimization, helping businesses leverage the platform's
                full potential to drive e-commerce growth.
              </IParagraph>
            </div>
            <div className="grid grid-cols-1 mt-20 md:grid-cols-2 lg:grid-cols-3 gap-x-10 xl:gap-x-20 gap-y-20">
              {experties.map((item: any, index: number) => {
                return (
                  <div
                    className="relative p-6 pt-10 duration-300 border-4 border-gray-600 group md:p-8 lg:pt-16 hover:border-white"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="absolute px-4 bg-gray-800 help-icon-box dark:bg-primary-900 -top-10">
                      <img src={item.imgSrc} alt="dddd" />
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <IParagraph color="gray">
                      <ul className="py-4 space-y-3 list-disc">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Services Offered</IHeading>
            </div>
            <div className="grid grid-cols-1 mb-12 lg:grid-cols-2 gap-x-10 gap-y-28 mt-36">
              {items.map((item: any) => {
                return (
                  <div
                    className="flex flex-col items-center p-5 rounded-lg shadow-xl bg-slate-700"
                    data-aos="fade-up"
                  >
                    <div className="flex items-center justify-center mb-6 -mt-20 overflow-hidden rounded-full icon-box w-28 md:w-40 h-28 md:h-40 bg-slate-200">
                      {item.imgSrc}
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <div className="mt-3 mb-6">
                      <ILine />
                    </div>
                    <IParagraph color="gray">
                      <ul className="px-16 space-y-3 list-disc">
                        {item.desc
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-28">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Why Choose Us for Shopify</IHeading>
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mt-14">
              {helpItems.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center p-10 overflow-hidden border-b-2 rounded-md shadow-xl group border-slate-600 dark:border-primary-400 dark:bg-primary-900"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="z-10 mb-6 startup-help-icon-box">
                      {item.icon}
                    </div>
                    <IHeading size="subHeader" align="text-center z-10">
                      {item.title}
                    </IHeading>
                    <p className="absolute top-0 bottom-0 left-0 right-0 z-0 p-8 text-gray-200 duration-300 opacity-0 bg-slate-600 dark:bg-primary-600 group-hover:opacity-100 group-hover:z-20">
                      <ul className="space-y-3 list-disc ">
                        {item.content
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <FAQs faqs={faqData} />
        <CallToAction
          title="Accelerate Your E-commerce Growth with BigCommerce"
          description="Ready to take your online store to new heights? Our BigCommerce experts are here to help you build, optimize, and scale your e-commerce business for maximum success."
          cta="Boost Your BigCommerce Store"
          ctaTo="/contact-us"
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query BigcommercePageQuery {
    mdx(
      fileAbsolutePath: { regex: "/(technologies)/" }
      slug: { regex: "/(technologies/bigcommerce)/" }
    ) {
      excerpt(pruneLength: 1000)
      frontmatter {
        title
        iconName
        description
      }
    }
  }
`
export default BigcommercePage
